<template>
  <Modal
    v-if="modal"
    :title="titleComputed"
    modal-size="modal-lg"
    @close="close"
  >
    <template slot="body">
      <div class="row">
        <div class="col-8 sub-title">
          <strong>
            {{ hintComputed }}
          </strong>
        </div>
        <div class="col-4">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-green"
              :disabled="useButtonDisabled"
              @click="replace"
            >
              <i class="fa fa-check"></i>
              {{ useButtonComputed }}
            </button>
            <button
              v-if="showConcatButton"
              type="button"
              class="btn btn-green"
              :disabled="!selected"
              @click="concat"
            >
              <i class="fa fa-plus"></i>
              {{ `${$t('article.ai_service.add_after')} ${generatorType}` }}
            </button>
          </div>
        </div>
      </div>
      <DataTable
        v-if="generatorType === 'perex'"
        :data="items"
        :config="dataTableConfig"
        :totalCount="items.length"
        :page="1"
        :pagination="false"
        :selectable="true"
        :disable-multiple-select="true"
        @select-record="selectItem"
      />
      <AiSelectModalForm
        v-else
        :generator-type="generatorType"
        :generated-items="generatedItems"
        @updateSelections="selectTitle"
      />
      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-info btn-generate"
          @click="$emit('generate')"
        >
          <span class="btn-generate__content">
            <IconArrowsRotate class="btn-generate__icon" />
          </span>
          {{ $t('article.ai_service.generate_from_ai_again') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import CoreApi from '@/api/core'
import Modal from '@/components/shared/Modal'
import DataTable from '@/components/shared/DataTable'
import AiSelectModalForm from './AiSelectModalForm'
import IconArrowsRotate from '@/assets/img/svg/arrows-rotate-solid.svg?inline'

export default {
  name: 'AiSelectModal',
  props: {
    generatedItems: {
      type: Array,
      required: true
    },
    originalText: {
      type: String,
      required: false
    },
    generatorType: {
      type: String,
      required: false
    },
    showConcatButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: false,
      selected: '',
      selectedTitles: []
    }
  },
  computed: {
    titleComputed () {
      return this.$t(`article.ai_service.ai_modal.modal_${this.generatorType}_heading`)
    },
    hintComputed () {
      return this.$t(`article.ai_service.ai_modal.modal_${this.generatorType}_hint`)
    },
    useButtonComputed () {
      if (this.generatorType === 'title' || this.generatorType === 'keyword') {
        return this.$t('article.ai_service.ai_modal.modal_use_button')
      } else {
        return `${this.$t('article.ai_service.replace')} ${this.generatorType}`
      }
    },
    useButtonDisabled () {
      return !this.selected && this.selectedTitles.length === 0
    },
    items () {
      return this.generatedItems.map(item => ({ id: item, text: item }))
    },
    dataTableConfig () {
      return {
        fields: {
          text: ''
        }
      }
    }
  },
  components: {
    Modal,
    DataTable,
    AiSelectModalForm,
    IconArrowsRotate
  },
  methods: {
    open () {
      this.selected = ''
      this.selectedTitles = []
      this.modal = true
    },
    close () {
      this.modal = false
    },
    selectItem (selected) {
      this.selected = selected.length === 0 ? '' : selected[0].text
    },
    selectTitle (selected) {
      this.selectedTitles = selected.length === 0 ? [] : selected
    },
    async replace () {
      if (this.selected) {
        this.$emit('selected', this.selected)
      }
      if (this.selectedTitles.length > 0) {
        this.$emit('selectedTitles', this.selectedTitles)
      }
      if (this.generatorType === 'keyword') {
        const selectedKeywords = this.selectedTitles.map(keyword => ({ title: keyword.value }))
        const payload = {
          tags: selectedKeywords,
          batchSize: this.selectedTitles.length
        }
        const tags = (await CoreApi().post('/Tag/multi-tag', payload)).data
        this.$emit('addTags', tags)
      }
    },
    concat () {
      this.$emit('selected', `${this.originalText} ${this.selected}`)
    }
  }
}
</script>

<style scoped lang="scss">
.sub-title {
  display: flex;
  align-items: flex-end;
}
.btn-generate {
  display: flex;
  gap: rem(5px);
  align-items: flex-end;
  &__content {
    width: rem(17px);
    height: rem(17px);
  }
  &__icon {
    fill: #FFFFFF;
  }
}
</style>
